import { Addon, PaymentStrategy, PlanId, ShopifyOnlineStore } from '@packages/types'
import { useQuery } from '@tanstack/react-query'
import React, { useState } from 'react'

import { useOnlineStoreService } from 'cms/onlineStores'
import { useAddonService } from 'common/addon'
import { Alert, FullPageModal, ModalProps } from 'common/components'
import { usePlanService } from 'common/plan'

import { useSubscriptionService } from '../../hooks'
import {
  ShopifyTotalCard,
  WixTotalCard,
  StripeTotalCard,
  AddonCard,
  PlanCard,
  StripeActivationFeedbackCard,
  EntreprisePlanCard,
} from '../cards'
import SubscriptionCheckList from '../SubscriptionCheckList'
import SubscriptionQuestionAndAnswer from '../SubscriptionQuestionAndAnswer'
import StripeActivationPageModal from './StripeActivationPageModal'

interface FullPageBillingPlansProps extends Omit<ModalProps, 'children'> {
  onClose: () => void
}

export enum TypeOfModalPage {
  stripe = 'stripe',
  stripeConfirmation = 'stripe_confirmation',
  default = 'default',
}

const customizationExperiencesAdvantages = {
  title: 'Customization experience',
  checklist: [
    'Live preview',
    'Multi-view product',
    'Dynamic pricing',
    'Unlimited variants',
    'Multi-component product',
    'Product bundle',
    'Image upload',
    'Text & font',
    'Multi-language',
    'Multi-currency',
  ],
}

const adminTools = {
  title: 'Admin tool',
  checklist: [
    'Theme editor',
    'Live coloring tool',
    'Print-ready files',
    'User content moderation',
    'SKU management',
    'API access',
    'Dependencies / logics',
  ],
}

const questionAnswerTuples = [
  {
    question: 'Can I connect more than one store to my Kickflip account?',
    answer: 'Yes, your Kickflip account can be connected to an unlimited number of stores.',
  },
  {
    question: 'Can I cancel my account anytime?',
    answer: 'Yes. You can cancel your account anytime.',
  },
  {
    question: 'Do I need to pay for hosting?',
    answer: 'No. Hosting is included with Kickflip and bandwidth is unlimited!',
  },
  {
    question: 'How do you calculate the transaction fee?',
    answer: 'The transaction fee applies to all orders processed and confirmed through Kickflip.',
  },
  {
    question: 'Can I change my plan anytime?',
    answer: 'Yes. You can upgrade or downgrade your plan anytime.',
  },
]

const planUnavailableString = "We are no longer offering your plan. It can't be recovered if you change plans."

const SubscriptionFullPageModal = ({ onClose, ...modalProps }: FullPageBillingPlansProps) => {
  const [typeOfModal, setTypeOfModal] = useState(TypeOfModalPage.default)
  const [addonsSubscribed, setAddonsSubscribed] = useState<Addon[]>([])
  const [initialized, setInitialized] = useState<boolean>(false)

  const addonService = useAddonService()
  const subscriptionService = useSubscriptionService()
  const onlineStoreService = useOnlineStoreService()
  const planService = usePlanService()

  const { data: availableAddons } = useQuery(addonService.fetchAll.queryKeys, addonService.fetchAll)
  const { data: subscription, refetch: refetchSubscription } = useQuery(
    subscriptionService.fetchOne.queryKeys,
    subscriptionService.fetchOne,
    {
      onSuccess: data => {
        if (!initialized) {
          setAddonsSubscribed(data?.addons)
          setInitialized(true)
        }
      },
    }
  )
  const { data: onlineStores } = useQuery(onlineStoreService.fetchAll.queryKeys, onlineStoreService.fetchAll, {
    initialData: [],
  })
  const { data: availablePlans } = useQuery(planService.fetchAll.queryKeys, planService.fetchAll)

  const handleClose = () => {
    refetchSubscription()
    onClose()
  }

  if (!subscription || !availablePlans || !availableAddons || !onlineStores) return null

  const plan = availablePlans[0]
  const isPlanAvailable = !subscription.plan.custom && subscription.plan.planId === PlanId.PayAsYouGrow

  return (
    <FullPageModal className="flex" onClose={handleClose} {...modalProps}>
      <div className="flex px-16 items-start">
        {typeOfModal === TypeOfModalPage.default && (
          <div className="flex-col">
            {!isPlanAvailable && (
              <Alert aria-label="legacy plan warning" variant="warning" className="my-4">
                {planUnavailableString}
              </Alert>
            )}
            <div className="flex items-start space-x-6 mt-4">
              <div className="flex flex-col">
                <div className="flex space-x-6">
                  <PlanCard plan={plan} />
                  <AddonCard
                    availableAddons={availableAddons}
                    subscriptionAddons={addonsSubscribed}
                    onAdd={addon => {
                      setAddonsSubscribed([...addonsSubscribed, addon])
                    }}
                    onRemove={addon => {
                      setAddonsSubscribed(addonsSubscribed.filter(a => a.addonId !== addon.addonId))
                    }}
                  />
                </div>
                <EntreprisePlanCard />
                <div className="flex flex-col space-y-3 pt-3 font-p2">
                  <span>
                    *If you are not selling online and only using Kickflip to generate quotes, an Enterprise plan is
                    required.
                  </span>
                  <span>*All prices are in US dollars</span>
                </div>
              </div>
              {subscription.paymentStrategy === PaymentStrategy.Shopify && (
                <ShopifyTotalCard
                  onlineStores={onlineStores as ShopifyOnlineStore[]}
                  plan={plan}
                  subscription={subscription}
                  addons={addonsSubscribed}
                />
              )}
              {subscription.paymentStrategy === PaymentStrategy.Wix && (
                <WixTotalCard
                  onlineStores={onlineStores}
                  plan={plan}
                  subscription={subscription}
                  addons={addonsSubscribed}
                  onSuccess={handleClose}
                />
              )}
              {subscription.paymentStrategy === PaymentStrategy.Stripe && (
                <StripeTotalCard
                  subscription={subscription}
                  plan={plan}
                  addons={addonsSubscribed}
                  onContinue={() => setTypeOfModal(TypeOfModalPage.stripe)}
                  onChangePlan={handleClose}
                />
              )}
            </div>
            <div className="pt-16 space-y-16">
              <div>
                <h1 className="pb-5">Included in all plans</h1>
                <SubscriptionCheckList
                  list={customizationExperiencesAdvantages.checklist}
                  title={customizationExperiencesAdvantages.title}
                />
                <SubscriptionCheckList list={adminTools.checklist} title={adminTools.title} />
              </div>
              <div>
                <h1 className="pb-5">Frequently asked questions</h1>
                <div className="grid grid-cols-2 gap-12 pb-20">
                  {questionAnswerTuples.map((item, index) => {
                    return <SubscriptionQuestionAndAnswer key={index} question={item.question} answer={item.answer} />
                  })}
                </div>
              </div>
            </div>
          </div>
        )}
        {typeOfModal === TypeOfModalPage.stripe && subscription.paymentStrategy === PaymentStrategy.Stripe && (
          <StripeActivationPageModal
            plan={plan}
            addons={addonsSubscribed}
            goBack={() => setTypeOfModal(TypeOfModalPage.default)}
            onSuccess={() => setTypeOfModal(TypeOfModalPage.stripeConfirmation)}
          />
        )}
        {typeOfModal === TypeOfModalPage.stripeConfirmation && <StripeActivationFeedbackCard />}
      </div>
    </FullPageModal>
  )
}

export default SubscriptionFullPageModal
