import { AnyAction } from 'redux'

import { actionTypes as customizationTypes } from 'customizer/customization'
import isNullOrEmpty from 'utils/isNullOrEmpty'

import * as actionTypes from './actionTypes'
import { Pricing } from './types'

const initialState = {
  basePrice: 0,
  currency: 'USD',
}

export default (state: Pricing = initialState, action: AnyAction): Pricing => {
  switch (action.type) {
    case customizationTypes.START_CUSTOMIZATION:
    case customizationTypes.START_DESIGN_CUSTOMIZATION:
      return isNullOrEmpty(action.payload.pricing) ? state : action.payload.pricing
    case actionTypes.SET_PRICING:
      return action.payload || initialState
    default:
      return state
  }
}
