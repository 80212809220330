import { Subscription } from '@packages/types'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import React from 'react'

import { Alert, Button, useToast } from 'common/components'
import { ToastType } from 'common/components/toast/types'

import { useSubscriptionService } from '../hooks'

interface MasterCustomPlanAlertProps {
  handleEditModal: () => void
  subscription: Subscription
}

const MasterCustomPlanAlert = ({ handleEditModal, subscription }: MasterCustomPlanAlertProps) => {
  const { openToast } = useToast()
  const subscriptionService = useSubscriptionService()
  const queryClient = useQueryClient()

  const { mutate: deletePlan } = useMutation(subscriptionService.deleteCustomPlan, {
    onSuccess: () => {
      queryClient.invalidateQueries(subscriptionService.fetchOne.queryKeys)
      openToast('Custom plan successfully deleted', ToastType.success)
    },
  })

  return (
    <>
      <Alert variant="info" aria-label="owner custom plan alert" className="w-full">
        <div className="flex-col">
          <div className="font-medium">
            A custom plan has been created for this client. The plan has not been accepted yet.
          </div>
          <div className="mt-2 space-x-1">
            <span>Monthly fee:</span>
            <span className="font-bold">${subscription.proposedCustomPlan?.price.recurringAmount}</span>
            <span className="font-medium">/</span>
            <span>Transaction fee:</span>
            <span className="font-bold">{subscription.proposedCustomPlan?.price.transactionFeePercentage}%</span>
            <span className="font-medium">/</span>
            <span>Product limit:</span>
            <span className="font-bold">
              {subscription.proposedCustomPlan?.features.productLimit
                ? subscription.proposedCustomPlan?.features.productLimit
                : 'unlimited'}
            </span>
            <span className="font-medium">/</span>
            <span>White label:</span>
            <span className="font-bold">
              {subscription.proposedCustomPlan?.features.whiteLabel ? 'included' : 'not included'}
            </span>
          </div>
          <div className="flex justify-end mt-2">
            <Button aria-label="Edit custom plan" variant="subtle" className="mx-1" onClick={handleEditModal}>
              Edit plan
            </Button>
            <Button
              variant="onColor"
              className="mx-1 text-error-default"
              onClick={() => {
                deletePlan()
              }}
            >
              Delete plan
            </Button>
          </div>
        </div>
      </Alert>
    </>
  )
}

export default MasterCustomPlanAlert
