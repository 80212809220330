import { Asset, BrandBusiness, DenormalizedQuote } from '@packages/types'
import { StyleSheet, View, Text, Image } from '@react-pdf/renderer'
import React from 'react'

import { selectCountryName } from 'cms/quotes/utils'
import { formatDisplayDate } from 'utils/dateUtils'

import { FONT_BOLD, NEUTRAL_400, NEUTRAL_600, NEUTRAL_900 } from './assets'

interface PrintableQuoteHeaderProps {
  infos?: BrandBusiness
  brandLogo?: Asset
  quote: DenormalizedQuote
}

const styles = StyleSheet.create({
  header: {
    paddingBottom: 12,
    paddingLeft: 40,
    paddingRight: 40,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  logo: {
    width: 58,
    height: 'auto',
    marginBottom: 24,
  },
  businessName: {
    color: NEUTRAL_900,
    paddingBottom: 12,
  },
  businessDetails: {
    color: NEUTRAL_400,
  },
  addressDetails: {
    marginBottom: 12,
    gap: 4,
  },
  quoteSection: {
    gap: 4,
  },
  quoteDetails: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end',
    justifyContent: 'space-between',
    gap: 18,
  },
  quoteDetailsTitle: {
    color: NEUTRAL_400,
  },
  quoteId: {
    color: NEUTRAL_900,
    fontSize: 12,
    fontFamily: FONT_BOLD,
  },
  date: {
    color: NEUTRAL_600,
  },
})

const PrintableQuoteHeader = ({ infos, brandLogo, quote }: PrintableQuoteHeaderProps) => {
  const countryName = selectCountryName(infos?.address?.countryCode)

  return (
    <View style={styles.header}>
      <View>
        {brandLogo?.url && (
          <View>
            <Image src={brandLogo.url} style={styles.logo} />
          </View>
        )}
        {infos && (
          <View style={styles.businessDetails}>
            {infos.name && <Text style={styles.businessName}>{infos.name}</Text>}
            <View style={styles.addressDetails}>
              {infos.address?.address && <Text>{infos.address.address}</Text>}
              {infos.address?.city && infos.address?.provinceCode && (
                <Text>{`${infos.address.city}, ${infos.address.provinceCode}`}</Text>
              )}
              {infos.address?.countryCode && infos.address?.zip && (
                <Text>{`${countryName}, ${infos.address.zip}`}</Text>
              )}
            </View>
            <View style={styles.addressDetails}>
              {infos?.phone && <Text>{infos.phone}</Text>}
              {infos?.email && <Text>{infos.email}</Text>}
            </View>
          </View>
        )}
      </View>
      <View style={styles.quoteSection}>
        <View style={styles.quoteDetails}>
          <Text style={styles.quoteDetailsTitle}>Quote ID</Text>
          <Text style={styles.quoteId}>{`QT${quote.quoteId}`}</Text>
        </View>
        <View style={styles.quoteDetails}>
          <Text style={styles.quoteDetailsTitle}>Creation date</Text>
          <Text style={styles.date}>{formatDisplayDate(quote.createdAt)}</Text>
        </View>
        <View style={styles.quoteDetails}>
          <Text style={styles.quoteDetailsTitle}>Expiration date</Text>
          <Text style={styles.date}>{quote.expireOn ? formatDisplayDate(quote.expireOn) : '-'}</Text>
        </View>
      </View>
    </View>
  )
}

export default PrintableQuoteHeader
