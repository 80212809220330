import { OnlineStoreStatus, CustomizerProduct, NormalizedCustomizerProduct, DenormalizedProduct } from '@packages/types'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { Formik } from 'formik'
import { History, Location } from 'history'
import React from 'react'
import { generatePath, match } from 'react-router'
import { Link } from 'react-router-dom'
import { useQueryParams, StringParam } from 'use-query-params'
import * as yup from 'yup'

import { Loader } from 'builder/common/components'
import GlobalRouterContext from 'builder/common/GlobalRouterContext'
import { Page } from 'builder/components'
import {
  TopBar,
  TopBarSection,
  TopBarProductName,
  TopBarProductActions,
  TopBarBackButton,
  TopBarTabs,
} from 'builder/topBar/components'
import { useOnlineStoreService } from 'cms/onlineStores'
import { CardTabs, useToast } from 'common/components'
import { ToastType } from 'common/components/toast/types'
import { customizerProductsUtils } from 'common/customizerProducts'
import { useProductService } from 'common/products'

import { toFormValues, fromFormValues } from '../convertPricingValues'
import { PricingFormValues, ProductPricingWithId } from '../types'
import { extraPriceSchema } from './AddExtraPricePopover'
import EmptyPricing from './EmptyPricing'
import { equationSchema } from './equation'
import OnlineStoreCard from './OnlineStoreCard'
import PricingForm from './PricingForm'

const queryParamsConfig = {
  onlineStoreId: StringParam,
}

const pricingSchema = yup.object().shape({
  basePrice: yup
    .number()
    .required('Required')
    .typeError('Please enter a valid price')
    .min(0, 'Price must be positive')
    .noWhitespace(),
  applyTaxes: yup.bool(),
  taxes: yup.number().when('applyTaxes', {
    is: (applyTaxes: boolean) => applyTaxes === true,
    then: () =>
      yup
        .number()
        .required('Required')
        .min(0, 'Taxes must be a positive number')
        .typeError('Taxes must be a number')
        .noWhitespace(),
  }),
  bulkPrices: yup.array().of(
    yup.object().shape({
      quantity: yup.number().required('Required').noWhitespace(),
      price: yup
        .number()
        .required('Required')
        .typeError('Please enter a valid price')
        .min(0, 'Price must be positive')
        .noWhitespace(),
    })
  ),
  extraPrices: yup.array().of(extraPriceSchema),
  equations: yup.array().of(equationSchema),
})

interface PricingProps {
  history: History
  location: Location
  match: match<{ productId: string }>
}

const Pricing = ({ history, location, match }: PricingProps) => {
  const { openToast, openGenericErrorToast } = useToast()
  const queryClient = useQueryClient()
  const productService = useProductService()
  const onlineStoreService = useOnlineStoreService()

  const { data: [product, customizerProduct] = [], isLoading: isLoadingProduct } = useQuery(
    productService.fetch.queryKeys,
    async () => {
      const result = await productService.fetch(match.params.productId, {
        params: { fields: ['startingPoints', 'live'] },
      })
      return result as DenormalizedProduct
    },
    {
      select: (data): [DenormalizedProduct, NormalizedCustomizerProduct] => [
        data,
        customizerProductsUtils.normalize(data.live as CustomizerProduct),
      ],
    }
  )

  const { data: onlineStores, isLoading: isLoadingOnlineStores } = useQuery(
    onlineStoreService.fetchAll.queryKeys,
    onlineStoreService.fetchAll,
    {
      refetchOnWindowFocus: false,
      initialData: [],
      select: data => data.filter(({ status }) => status === OnlineStoreStatus.Installed),
    }
  )

  const { mutate: updatePricing } = useMutation(
    (pricing: ProductPricingWithId[]) => onlineStoreService.bulkUpdatePricing(match.params.productId, pricing),
    {
      onSuccess: () => {
        openToast(`${product?.name} pricing has been successfully published`, ToastType.success)
        queryClient.invalidateQueries(onlineStoreService.fetchAll.queryKeys)
      },
      onError: () => {
        openGenericErrorToast(`${product?.name} pricing has not been published.`)
        queryClient.invalidateQueries(onlineStoreService.fetchAll.queryKeys)
      },
    }
  )

  const [query] = useQueryParams(queryParamsConfig, { updateType: 'replace' })

  const selectedStore = query.onlineStoreId
    ? onlineStores.find(({ id }) => id === query.onlineStoreId)
    : onlineStores[0]

  const pricing = {
    ...selectedStore?.productsData?.[match.params.productId]?.pricing,
    id: selectedStore?.id,
  } as ProductPricingWithId

  const handleSubmit = (
    values: PricingFormValues,
    { setSubmitting }: { setSubmitting: (isSubmitting: boolean) => void }
  ) => {
    updatePricing(fromFormValues([values]), { onSettled: () => setSubmitting(false) })
  }

  return (
    <GlobalRouterContext.Provider value={{ history, location, match }}>
      {isLoadingProduct || isLoadingOnlineStores || !product || !customizerProduct ? (
        <Loader />
      ) : (
        <>
          <TopBar>
            <TopBarSection className="pl-5">
              <TopBarBackButton />
              <TopBarProductName />
              <TopBarProductActions />
            </TopBarSection>
            <TopBarTabs />
          </TopBar>
          <Page variant="lg">
            {onlineStores.length === 0 && <EmptyPricing />}
            {onlineStores.length !== 0 && selectedStore && (
              <>
                <h1 className="mb-4">Pricing</h1>
                <hr className="h-[1px] w-full border-neutral-100 mb-4" />
                <div className="mt-8" role="form">
                  <div className="flex">
                    <div className="flex flex-col space-y-2 mr-12 basis-64 w-64 shrink-0 pb-4">
                      <div className="font-medium mb-2 h-8 flex items-center">Online stores</div>
                      <CardTabs>
                        {onlineStores.map(onlineStore => (
                          <Link
                            key={onlineStore.id}
                            replace
                            to={`${generatePath(match.path, match.params)}?onlineStoreId=${onlineStore.id}`}
                          >
                            <OnlineStoreCard
                              onlineStore={onlineStore}
                              selected={selectedStore?.id === onlineStore.id}
                            />
                          </Link>
                        ))}
                      </CardTabs>
                    </div>

                    <Formik
                      initialValues={toFormValues(pricing)}
                      onSubmit={handleSubmit}
                      validationSchema={pricingSchema}
                      enableReinitialize
                    >
                      <PricingForm onlineStore={selectedStore} customizerProduct={customizerProduct} />
                    </Formik>
                  </div>
                </div>
              </>
            )}
          </Page>
        </>
      )}
    </GlobalRouterContext.Provider>
  )
}

export default Pricing
