import { useMutation, useQueryClient } from '@tanstack/react-query'
import React, { useContext, useEffect } from 'react'

import { TenantContext } from 'common/tenant'
import { useCurrentUser } from 'common/users'

import { useAuthService } from '../hooks'

interface AuthenticatedRoutesProps {
  children: React.ReactNode
}

const AuthenticatedRoutes = ({ children }: AuthenticatedRoutesProps) => {
  const authService = useAuthService()
  const { isMCZRUser, isLoadingCurrentUser } = useCurrentUser()
  const tenant = useContext(TenantContext)
  const queryClient = useQueryClient()

  const { mutate: updateToken } = useMutation(authService.updateToken, {
    onSuccess: data => {
      if (data.token) {
        localStorage.setItem('bearer', data.token)
        queryClient.invalidateQueries([tenant])
      }
    },
  })

  useEffect(() => {
    if (!isLoadingCurrentUser && !isMCZRUser) {
      updateToken()
    }
  }, [isLoadingCurrentUser, isMCZRUser])

  return <>{(!isLoadingCurrentUser || isMCZRUser) && children}</>
}

export default AuthenticatedRoutes
