import { AdminUserRole, BrandUserRole } from '@packages/types'
import React, { useEffect } from 'react'
import { Redirect, Route, Switch, useLocation, useRouteMatch } from 'react-router'

import { Builder } from 'builder/build/components'
import { Connect, StartingPointEditor } from 'builder/connect/components'
import { Pricing } from 'builder/pricing/components'
import { Variants } from 'builder/variants/components'
import { ProtectedRoute, AuthenticatedRoutes } from 'cms/auth/components'
import Business from 'cms/business/components/Business'
import { Design, Designs } from 'cms/designs/components'
import { ProductHistory } from 'cms/history/components'
import ThemeHistory from 'cms/history/components/ThemeHistory'
import { Inventory } from 'cms/inventory/components'
import { OnlineStores, OnlineStore, OnlineStoreCurrencies, CreateOnlineStore } from 'cms/onlineStores/components'
import { Order, Orders } from 'cms/orders/components'
import { Plugin, Plugins } from 'cms/plugins/components'
import { CustomizerPreview } from 'cms/preview/components'
import { Products } from 'cms/products/components'
import { Quotes, Quote, CreateQuote } from 'cms/quotes/components'
import { BillingAndSubscription } from 'cms/subscription/components'
import { ThemeBuilder } from 'cms/theme/components'
import { Tokens, CreateToken } from 'cms/tokens/components'
import { Translations, Translation } from 'cms/translations/components'
import { Users, CreateUser, EditUser } from 'cms/users/components'
import { WordFilter } from 'cms/wordFilter/components'
import { ErrorPage } from 'common/components'
import { useCurrentUser, usersUtils } from 'common/users'
import {
  load as loadIntercom,
  boot as bootIntercom,
  update as updateIntercom,
  shutdown as shutdownIntercom,
} from 'intercom'

const adminRoles = [AdminUserRole.Master, AdminUserRole.MczrAdmin, BrandUserRole.Admin]

const BrandRoutes = () => {
  const location = useLocation()
  const match = useRouteMatch()
  const baseUrlTemplate = match.path === '/' ? '' : match.path
  const { currentUser } = useCurrentUser()
  const moveIntercomMatch = useRouteMatch({ path: '*/(builder|theme)' })

  useEffect(() => {
    if (currentUser) {
      loadIntercom()
      bootIntercom({ email: currentUser.email, name: usersUtils.getFullName(currentUser) })
    }

    return () => shutdownIntercom()
  }, [currentUser])

  useEffect(() => {
    updateIntercom()
  }, [location.pathname])

  useEffect(() => {
    if (moveIntercomMatch) updateIntercom({ horizontal_padding: 270, vertical_padding: -60 })
    else updateIntercom({ horizontal_padding: -60, vertical_padding: -60 })
  }, [moveIntercomMatch])

  return (
    <Switch>
      <Redirect exact from={`${baseUrlTemplate}/`} to={`${baseUrlTemplate}/products`} />
      <Redirect exact from={`${baseUrlTemplate}/home`} to={`${baseUrlTemplate}/products`} />
      <ProtectedRoute
        allowedRoles={adminRoles}
        path={`${baseUrlTemplate}/inventory`}
        component={Inventory}
        feature="inventory_management"
      />
      <ProtectedRoute
        exact
        allowedRoles={adminRoles}
        path={`${baseUrlTemplate}/subscription`}
        component={BillingAndSubscription}
      />
      <AuthenticatedRoutes>
        <Switch>
          <ProtectedRoute exact allowedRoles={adminRoles} path={`${baseUrlTemplate}/products`} component={Products} />
          <ProtectedRoute
            allowedRoles={adminRoles}
            path={`${baseUrlTemplate}/products/:productId/builder`}
            component={Builder}
          />
          <ProtectedRoute
            allowedRoles={adminRoles}
            path={`${baseUrlTemplate}/products/:productId/pricing`}
            component={Pricing}
          />
          <ProtectedRoute
            allowedRoles={adminRoles}
            feature="inventory_management"
            path={`${baseUrlTemplate}/products/:productId/variants`}
            component={Variants}
          />
          <ProtectedRoute
            allowedRoles={adminRoles}
            path={[
              `${baseUrlTemplate}/products/:productId/startingpoints/create`,
              `${baseUrlTemplate}/products/:productId/startingpoints/:startingPointId`,
            ]}
            component={StartingPointEditor}
          />
          <ProtectedRoute
            allowedRoles={adminRoles}
            path={`${baseUrlTemplate}/products/:productId/connect`}
            component={Connect}
          />
          <ProtectedRoute
            allowedRoles={adminRoles}
            path={`${baseUrlTemplate}/products/:productId/history`}
            component={ProductHistory}
          />
          <ProtectedRoute
            allowedRoles={adminRoles}
            path={`${baseUrlTemplate}/demos/:productId/builder`}
            component={Builder}
          />
          <ProtectedRoute exact allowedRoles={adminRoles} path={`${baseUrlTemplate}/users`} component={Users} />
          <ProtectedRoute
            exact
            allowedRoles={adminRoles}
            path={`${baseUrlTemplate}/users/create`}
            component={CreateUser}
          />
          <ProtectedRoute exact allowedRoles={adminRoles} path={`${baseUrlTemplate}/users/:id`} component={EditUser} />
          <ProtectedRoute exact allowedRoles={adminRoles} path={`${baseUrlTemplate}/tokens`} component={Tokens} />
          <ProtectedRoute
            exact
            allowedRoles={adminRoles}
            path={`${baseUrlTemplate}/tokens/create`}
            component={CreateToken}
          />
          <ProtectedRoute
            exact
            allowedRoles={adminRoles}
            path={`${baseUrlTemplate}/onlinestores`}
            component={OnlineStores}
          />
          <ProtectedRoute
            exact
            allowedRoles={adminRoles}
            path={`${baseUrlTemplate}/onlinestores/create`}
            component={CreateOnlineStore}
          />
          <ProtectedRoute
            allowedRoles={adminRoles}
            exact
            path={`${baseUrlTemplate}/onlinestores/:id/currencies`}
            component={OnlineStoreCurrencies}
          />
          <ProtectedRoute
            exact
            allowedRoles={adminRoles}
            path={`${baseUrlTemplate}/translations`}
            component={Translations}
          />
          <ProtectedRoute
            allowedRoles={adminRoles}
            exact
            path={`${baseUrlTemplate}/onlinestores/:id`}
            component={OnlineStore}
          />
          <ProtectedRoute
            exact
            allowedRoles={adminRoles}
            path={`${baseUrlTemplate}/business`}
            component={Business}
            feature="quote_phase_1"
          />
          <ProtectedRoute
            exact
            allowedRoles={adminRoles}
            path={`${baseUrlTemplate}/translations/:languageCode`}
            component={Translation}
          />
          <ProtectedRoute
            exact
            allowedRoles={adminRoles}
            path={`${baseUrlTemplate}/preview`}
            component={CustomizerPreview}
          />
          <ProtectedRoute
            exact
            allowedRoles={adminRoles}
            path={`${baseUrlTemplate}/wordfilter`}
            component={WordFilter}
          />
          <ProtectedRoute exact allowedRoles={adminRoles} path={`${baseUrlTemplate}/theme`} component={ThemeBuilder} />
          <ProtectedRoute
            exact
            allowedRoles={adminRoles}
            path={`${baseUrlTemplate}/themes/:themeId`}
            component={ThemeBuilder}
          />
          <ProtectedRoute
            exact
            allowedRoles={adminRoles}
            path={`${baseUrlTemplate}/themes/:themeId/history`}
            component={ThemeHistory}
          />
          <ProtectedRoute
            allowedRoles={adminRoles}
            path={`${baseUrlTemplate}/translations/:translationCode`}
            component={Translation}
          />
          <ProtectedRoute allowedRoles={adminRoles} exact path={`${baseUrlTemplate}/designs`} component={Designs} />
          <ProtectedRoute allowedRoles={adminRoles} exact path={`${baseUrlTemplate}/designs/:id`} component={Design} />
          <ProtectedRoute allowedRoles={adminRoles} exact path={`${baseUrlTemplate}/orders`} component={Orders} />
          <ProtectedRoute allowedRoles={adminRoles} exact path={`${baseUrlTemplate}/orders/:id`} component={Order} />
          <ProtectedRoute
            allowedRoles={adminRoles}
            exact
            path={`${baseUrlTemplate}/quotes`}
            component={Quotes}
            feature="quote_phase_1"
          />
          <ProtectedRoute
            allowedRoles={adminRoles}
            exact
            path={`${baseUrlTemplate}/quotes/create`}
            component={CreateQuote}
            feature="quote_phase_2"
          />
          <ProtectedRoute
            allowedRoles={adminRoles}
            exact
            path={`${baseUrlTemplate}/quotes/:id/revise`}
            render={() => <Quote isRevision />}
            feature="quote_phase_2"
          />
          <ProtectedRoute
            allowedRoles={adminRoles}
            exact
            path={`${baseUrlTemplate}/quotes/:id`}
            component={Quote}
            feature="quote_phase_1"
          />
          <ProtectedRoute
            allowedRoles={[AdminUserRole.Master, AdminUserRole.MczrAdmin]}
            path={`${baseUrlTemplate}/plugins`}
            component={Plugins}
            exact
          />
          <ProtectedRoute
            allowedRoles={[AdminUserRole.Master, AdminUserRole.MczrAdmin]}
            path={`${baseUrlTemplate}/plugins/:id`}
            component={Plugin}
          />
          <Route path="*" render={() => <ErrorPage title="404" description="Page not found" />} />
        </Switch>
      </AuthenticatedRoutes>
    </Switch>
  )
}

export default BrandRoutes
