import { Answer, ColorView, DenormalizedCustomizableQuestion } from '@packages/types'
import React, { ForwardedRef, forwardRef } from 'react'

import { useThemeSettings } from 'customizer/theme'
import { Translation } from 'themes/common/components'

import ColorWheelIcon from './ColorWheelIcon'

import './ColorPickerButton.scss'

interface ColorPickerButtonProps {
  onClick: React.MouseEventHandler<HTMLButtonElement>
  question: DenormalizedCustomizableQuestion
  colorAnswers: Answer[]
}

const ColorPickerButton = forwardRef(
  ({ onClick, question, colorAnswers }: ColorPickerButtonProps, ref: ForwardedRef<HTMLButtonElement>) => {
    const colorPickerSettings = useThemeSettings('colorPicker')
    const answerSettings = useThemeSettings('answer')
    const summarySettings = useThemeSettings('summary')
    const isPredefinedColor = !!colorAnswers.find(answer => answer.id === question.selectedAnswer?.id)
    const color =
      (question.selectedAnswer?.views as ColorView[] | undefined)?.find(view => view.color)?.color || '#FFFFFF'

    return (
      <button
        className="color-picker-button"
        ref={ref}
        onClick={onClick}
        style={{
          borderColor: colorPickerSettings.buttonOutlineColor,
          outlineColor: colorPickerSettings.buttonOutlineColor,
          borderRadius: colorPickerSettings.answerButtonRounding,
        }}
      >
        <div className="color-picker-button__color-name-section">
          <ColorWheelIcon color={color} width={50} height={50} className="color-picker-button__wheel" />
          <div className="color-picker-button__color-name" style={{ color: answerSettings.answerFontColor }}>
            {isPredefinedColor ? question.selectedAnswer?.name : <Translation field="customColor" />}
          </div>
        </div>
        <div className="color-picker-button__color-value" style={{ color: summarySettings.color }}>
          {color.toUpperCase()}
        </div>
      </button>
    )
  }
)

export default ColorPickerButton
