import React, { useContext } from 'react'

import QuoteContext from 'cms/quotes/QuoteContext'
import { IconButton, Price, Table } from 'common/components'

import AddQuoteCustomExtraPricePopover from './AddQuoteCustomExtraPricePopover'
import { FieldArray, useFormikContext } from 'formik'
import { QuoteFormValues } from './Quote'
import QuoteTableEditableCell from './QuoteTableEditableCell'
import TrashIcon from 'icons/regular/01-Interface Essential/23-Delete/bin.svg'
import classMerge from 'utils/classMerge'
import { DesignType } from '@packages/types'

interface QuoteTableCustomExtraPricesProps {
  itemIndex: number
}

const QuoteTableCustomExtraPrices = ({ itemIndex }: QuoteTableCustomExtraPricesProps) => {
  const { isEditing, quote } = useContext(QuoteContext)
  const formik = useFormikContext<QuoteFormValues>()

  if (!quote) return null

  const design = formik.values.items[itemIndex]?.design
  const isBulk = design?.type === DesignType.BulkOrderDesign

  return (
    <>
      {isEditing && (
        <Table.HeaderRow className="bg-neutral-50 border-y border-neutral-100">
          <Table.HeaderCell className="px-6 " colSpan={3}>
            Custom details
          </Table.HeaderCell>
          <Table.HeaderCell className="flex justify-end items-center px-6 w-full text-right">
            <AddQuoteCustomExtraPricePopover itemIndex={itemIndex} />
          </Table.HeaderCell>
        </Table.HeaderRow>
      )}
      <FieldArray name={`items.${itemIndex}.design.customExtraPrices`}>
        {fieldArrayHelpers =>
          design?.customExtraPrices?.map((detail, index) => (
            <Table.Row className="border-t">
              <Table.Cell
                className={classMerge('py-3 px-6 flex flex-row items-center gap-2', {
                  'pl-10': isBulk && !isEditing,
                  'pl-2': isBulk && isEditing,
                })}
              >
                {isEditing && (
                  <IconButton
                    small
                    aria-label="Delete custom detail"
                    onClick={() => fieldArrayHelpers.remove(index)}
                    Icon={TrashIcon}
                    type="button"
                    variant="subtle"
                    className="text-neutral-500"
                  />
                )}
                <span>{detail.description}</span>
              </Table.Cell>
              <QuoteTableEditableCell
                className="w-2/12 pr-4"
                type="quantity"
                name={`items.${itemIndex}.design.customExtraPrices.${index}.quantity`}
                id={`items.${itemIndex}.design.customExtraPrices.${index}.quantity`}
                value={detail.quantity}
                onChange={formik.handleChange}
              />
              <QuoteTableEditableCell
                className="w-2/12"
                type="price"
                name={`items.${itemIndex}.design.customExtraPrices.${index}.price`}
                id={`items.${itemIndex}.design.customExtraPrices.${index}.price`}
                value={detail.price}
                onChange={formik.handleChange}
              />

              <Table.Cell className="text-right py-3 px-6" colSpan={1}>
                <Price
                  amount={detail.price * detail.quantity}
                  currency={quote.currency ?? 'USD'}
                  className="font-medium text-sm pl-1 text-neutral-900"
                />
              </Table.Cell>
            </Table.Row>
          ))
        }
      </FieldArray>
    </>
  )
}

export default QuoteTableCustomExtraPrices
