import { DenormalizedQuote } from '@packages/types'
import classNames from 'classnames'
import React from 'react'

import { Paginated } from 'common/api/types'
import { BlankState, Price, Table, Tag } from 'common/components'
import { SortOrder } from 'common/hooks/dataTable/useTableSort'
import { withFlag } from 'common/users/components'
import QuoteIcon from 'icons/regular/07-Work-Office-Companies/07-Office-Files/office-file-sheet.svg'
import { formatSmallDate } from 'utils/dateUtils'

import { QuotesSortKeys } from '../../types'
import { STATUS_FILTERS } from './QuotesFilterList'

type QuotesColumn = {
  key: QuotesSortKeys
  title: string
  className: string
  isSortable: boolean
  filterClassName?: string
  render: (quote: DenormalizedQuote) => React.ReactNode
}

const quotesColumns: QuotesColumn[] = [
  {
    key: 'quoteId',
    title: 'Id',
    className: 'w-1/12',
    isSortable: true,
    render: quote => (quote.quoteId != null ? `QT${quote.quoteId}` : <Table.EmptyCellIndicator />),
  },
  {
    key: 'store',
    title: 'Store',
    className: 'w-2/12',
    isSortable: true,
    render: quote => quote?.store?.name || <Table.EmptyCellIndicator />,
  },
  {
    key: 'customer',
    title: 'Customer email',
    className: 'w-2/12',
    isSortable: true,
    render: quote => quote?.customer?.email || <Table.EmptyCellIndicator />,
  },
  {
    key: 'totalPrice',
    title: 'Total',
    className: 'w-1/12',
    isSortable: true,
    render: quote =>
      quote.totalPrice != null ? (
        <Price amount={quote.totalPrice} currency={quote.currency ?? 'USD'} />
      ) : (
        <Table.EmptyCellIndicator />
      ),
  },
  {
    key: 'status',
    title: 'Status',
    className: 'w-2/12',
    isSortable: true,
    render: quote => {
      const status = STATUS_FILTERS.find(({ name }) => name === quote.status)
      return (
        <>
          <Tag className={classNames('rounded', status?.className)}>{status?.text || <Table.EmptyCellIndicator />}</Tag>
          {quote.sent && <Tag className="rounded bg-secondary-orange-75 color-neutral-800 ml-2">Sent</Tag>}
        </>
      )
    },
  },
  {
    key: 'createdAt',
    title: 'Created',
    className: 'w-2/12',
    isSortable: true,
    render: quote => (quote.createdAt ? formatSmallDate(quote.createdAt) : <Table.EmptyCellIndicator />),
  },
  {
    key: 'expireOn',
    title: 'Expiration',
    className: 'w-2/12',
    isSortable: true,
    render: quote => {
      if (!quote.expireOn) {
        return <Table.EmptyCellIndicator />
      }

      const now = new Date()
      const sevenDaysFromNow = new Date(now.getTime() + 7 * 24 * 60 * 60 * 1000)

      const isExpired = new Date(quote.expireOn) < now
      const isExpiringSoon = !isExpired && new Date(quote.expireOn) <= sevenDaysFromNow

      return (
        <div className={classNames(isExpiringSoon ? 'text-secondary-orange-300' : isExpired ? 'text-neutral-300' : '')}>
          {formatSmallDate(quote.expireOn)}
        </div>
      )
    },
  },
]

export interface QuotesTableProps {
  sortKey?: QuotesSortKeys
  sortOrder?: SortOrder
  handleSort: (key: QuotesSortKeys) => void
  setCount: (value: number) => void
  nextPage: () => void
  previousPage: () => void
  count: number
  lastIndex: number
  resultSize?: number
  collectionSize?: number
  data?: Paginated<DenormalizedQuote>
  isLoading?: boolean
  isFetching?: boolean
  onClearFilters: () => void
  onRowClick: (id: string) => void
  hasAppliedFilters: boolean
}

const QuotesTable = ({
  sortKey,
  sortOrder,
  handleSort,
  setCount,
  count,
  previousPage,
  nextPage,
  data,
  lastIndex,
  collectionSize,
  resultSize,
  isLoading,
  isFetching,
  onClearFilters,
  hasAppliedFilters,
  onRowClick,
}: QuotesTableProps) => {
  const isEmpty = !data?.results.length
  const numberOfColumns = quotesColumns.length

  return (
    <Table>
      {(!isEmpty || hasAppliedFilters) && (
        <Table.Header>
          <Table.Row>
            {quotesColumns.map(column => {
              return (
                <Table.HeaderCell key={column.key} className={column.className}>
                  <Table.HeaderFilter
                    onClick={() => handleSort(column.key)}
                    sortOrder={sortOrder}
                    isSortable={column.isSortable}
                    isSorted={sortKey === column.key}
                    className={column.filterClassName}
                  >
                    {column.title}
                  </Table.HeaderFilter>
                </Table.HeaderCell>
              )
            })}
          </Table.Row>
        </Table.Header>
      )}
      {isLoading && <Table.BodyLoader numberOfColumns={numberOfColumns} numberOfRows={count} />}

      {!isLoading && isEmpty && hasAppliedFilters && (
        <Table.Body>
          <Table.Row className="hover:bg-white border-b-0">
            <Table.Cell colSpan={numberOfColumns}>
              <div className="flex items-center justify-center py-32">
                <BlankState>
                  <BlankState.Icon Icon={QuoteIcon} />
                  <BlankState.Title>No Quotes to display with the specified filters</BlankState.Title>
                  <BlankState.Details>Remove or clear the applied filters</BlankState.Details>
                  <BlankState.Action onClick={onClearFilters}>Clear all filters</BlankState.Action>
                </BlankState>
              </div>
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      )}

      {!isLoading && isEmpty && !hasAppliedFilters && (
        <Table.Body>
          <Table.Row className="hover:bg-white border-b-0">
            <Table.Cell colSpan={5}>
              <div className="flex items-center justify-center py-32 ">
                <BlankState className="max-w-xs">
                  <BlankState.Icon Icon={QuoteIcon} />
                  <BlankState.Title>Customers haven't created quotes, yet.</BlankState.Title>
                  <BlankState.Details>
                    Quotes will appear here as soon as your customers request a quote.
                  </BlankState.Details>
                </BlankState>
              </div>
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      )}

      {!isLoading && !isEmpty && (
        <Table.Body>
          {isFetching && <Table.Loader colSpan={numberOfColumns} />}
          {data?.results.map(quote => {
            return (
              <Table.Row
                className="cursor-pointer"
                key={quote.quoteId}
                onClick={() => onRowClick(quote.id)}
                data-testid="row"
              >
                {quotesColumns.map(column => (
                  <Table.Cell key={column.key}>{column.render(quote)}</Table.Cell>
                ))}
              </Table.Row>
            )
          })}
        </Table.Body>
      )}

      {!isEmpty && (
        <Table.Footer>
          <Table.FooterRow>
            <Table.FooterCell colSpan={numberOfColumns}>
              <Table.Pagination>
                <Table.PaginationRowsPerPage onChange={setCount} rowsPerPage={count} />
                <Table.PaginationNavigation
                  onNextPage={nextPage}
                  onPreviousPage={previousPage}
                  lastIndex={lastIndex}
                  resultSize={resultSize || 0}
                  count={count}
                  collectionSize={collectionSize || 0}
                />
              </Table.Pagination>
            </Table.FooterCell>
          </Table.FooterRow>
        </Table.Footer>
      )}
    </Table>
  )
}

export default withFlag({
  Component: QuotesTable,
  feature: 'quote_phase_1',
})
