import { PaymentStrategy, Subscription, SubscriptionStatus } from '@packages/types'
import classNames from 'classnames'
import { format } from 'date-fns'
import React from 'react'

import { Card, Button, useModal } from 'common/components'

import { CancellationFeedbackModal, CancelSubscriptionModal, EditSubscriptionModal } from '../../modals'
import CancelFreeTrialModal from '../../modals/CancelFreeTrialModal'

interface SubscriptionCardActionsProps {
  subscription: Subscription
  onPlanChange: () => void
  isInstalled?: boolean
}

const SubscriptionCardActions = ({ subscription, onPlanChange, isInstalled }: SubscriptionCardActionsProps) => {
  const cancelFreeTrialModal = useModal()
  const cancelSubscriptionModal = useModal()
  const cancellationFeedbackModal = useModal()
  const editSubscriptionModal = useModal()

  const stripeFreeTrialActivated =
    subscription.status === SubscriptionStatus.FreeTrial &&
    subscription.paymentStrategy === PaymentStrategy.Stripe &&
    !!subscription.stripePaymentMethodId

  const isNone = subscription.paymentStrategy === PaymentStrategy.None
  const isActivateDisabled = (!isInstalled && subscription.paymentStrategy === PaymentStrategy.Shopify) || isNone

  let CardSection: React.ReactNode | null = null

  if (subscription.status === SubscriptionStatus.Active || stripeFreeTrialActivated) {
    CardSection = (
      <Card.Section className="flex justify-between py-3">
        <Button variant="default-error" onClick={cancelSubscriptionModal.open}>
          Cancel subscription
        </Button>
        <Button aria-label="edit plan" disabled={isActivateDisabled} variant="default" onClick={onPlanChange}>
          Edit plan
        </Button>
      </Card.Section>
    )
  } else if (subscription.status === SubscriptionStatus.RequestCancel) {
    CardSection = (
      <Card.Section
        className={classNames('flex items-center py-3', {
          'justify-between': subscription.cancelOn,
          'justify-end': !subscription.cancelOn,
        })}
      >
        {subscription.cancelOn && (
          <span className="text-neutral-900 text-sm">
            Cancellation date: {format(new Date(subscription.cancelOn), 'MMM d, yyyy')}
          </span>
        )}

        <Button aria-label="Edit subscription" disabled={isActivateDisabled} onClick={editSubscriptionModal.open}>
          Edit subscription
        </Button>
      </Card.Section>
    )
  } else if (subscription.status === SubscriptionStatus.Canceled) {
    CardSection = (
      <Card.Section className="flex justify-between items-center py-3">
        <span className="text-neutral-900 text-sm">
          Cancellation date: {format(new Date(subscription.canceledAt!), 'MMM d, yyyy')}
        </span>
        <Button aria-label="activate now" disabled={isActivateDisabled} variant="green" onClick={onPlanChange}>
          Activate now
        </Button>
      </Card.Section>
    )
  } else {
    CardSection = (
      <Card.Section className="flex justify-end py-3">
        <Button aria-label="activate now" disabled={isActivateDisabled} variant="green" onClick={onPlanChange}>
          Activate now
        </Button>
      </Card.Section>
    )
  }

  return (
    <>
      {CardSection}
      {cancelFreeTrialModal.isVisible && (
        <CancelFreeTrialModal {...cancelFreeTrialModal.modalProps} onClose={cancelFreeTrialModal.close} />
      )}
      {cancelSubscriptionModal.isVisible && (
        <CancelSubscriptionModal
          {...cancelSubscriptionModal.modalProps}
          onClose={(withFeedback?: boolean) => {
            withFeedback && cancellationFeedbackModal.open()
            cancelSubscriptionModal.close()
          }}
        />
      )}
      {cancellationFeedbackModal.isVisible && (
        <CancellationFeedbackModal
          {...cancellationFeedbackModal.modalProps}
          onClose={cancellationFeedbackModal.close}
        />
      )}
      {editSubscriptionModal.isVisible && (
        <EditSubscriptionModal {...editSubscriptionModal.modalProps} onClose={editSubscriptionModal.close} />
      )}
    </>
  )
}

export default SubscriptionCardActions
