import { DenormalizedQuote, ECommerce, WixOnlineStore } from '@packages/types'
import React from 'react'

import { Button } from 'common/components'
import ConvertToOrderIcon from 'icons/custom/convert-to-order.svg'

import QuoteWixCheckoutLink from './QuoteWixCheckoutLink'

const convertToOrderLabel: Record<ECommerce, string> = {
  [ECommerce.Wix]: 'Generate checkout link',
  [ECommerce.Shopify]: 'Generate Shopify order',
  [ECommerce.CustomStore]: 'Convert to order',
  [ECommerce.Prestashop]: 'Convert to order',
  [ECommerce.Woocommerce]: 'Convert to order',
}

type QuoteWithWixStore = Omit<DenormalizedQuote, 'store'> & { store: WixOnlineStore }

export interface QuoteConvertToOrderSectionProps {
  quote: DenormalizedQuote
  onConvertToOrder: React.MouseEventHandler<HTMLButtonElement>
  isLoading: boolean
}

const QuoteConvertToOrderSection = ({ quote, onConvertToOrder, isLoading }: QuoteConvertToOrderSectionProps) => {
  const isCheckoutUrlAvailable = quote.integration?.wix?.checkoutUrl

  if (isCheckoutUrlAvailable) {
    return (
      <>
        <QuoteWixCheckoutLink quote={quote as QuoteWithWixStore} />
        <hr className="h-auto border border-neutral-100" />
      </>
    )
  }

  return (
    <Button onClick={onConvertToOrder} disabled={isLoading} isLoading={isLoading} type="button">
      <ConvertToOrderIcon className="w-3 h-3 mr-2" />
      {convertToOrderLabel[quote.store.eCommerce]}
    </Button>
  )
}

export default QuoteConvertToOrderSection
