import {
  ShopifyInstalledTheme,
  ShopifyTheme,
  ShopifyProductDetails,
  LegacyCustomizerIntegrationType,
  ShopifyApp,
} from '@packages/types'
import { useContext } from 'react'

import { apiClient, createQuery } from 'common/api'
import { TenantContext } from 'common/tenant'

export type UpdateParams = {
  themeId: number
}

export type UpdateIntegrationParams = {
  integrationType: LegacyCustomizerIntegrationType
} & UpdateParams

const useShopifyProxyService = () => {
  const tenant = useContext(TenantContext)

  return {
    updateIntegration: async (onlineStoreId: string, { themeId, integrationType }: UpdateIntegrationParams) => {
      const { data } = await apiClient.post<void>(
        `/brands/${tenant}/shopify/proxy/${onlineStoreId}?path=${encodeURIComponent(`/themes/integration/update`)}`,
        { themeId, integrationType }
      )
      return data
    },
    fetchInstalledThemes: createQuery([tenant, 'shopify-installed-themes'], async (onlineStoreId: string) => {
      const { data } = await apiClient.get<ShopifyInstalledTheme[]>(
        `/brands/${tenant}/shopify/proxy/${onlineStoreId}?path=${encodeURIComponent(`/themes`)}`
      )
      return data
    }),
    fetchThemeAssetVersions: createQuery(
      [tenant, 'get-theme-asset-versions'],
      async (onlineStoreId: string, themeId: number) => {
        const { data } = await apiClient.get<{ version: string; lastVersion: string }>(
          `/brands/${tenant}/shopify/proxy/${onlineStoreId}?path=${encodeURIComponent(
            `/themes/get-asset-versions/${themeId}`
          )}`
        )
        return data
      }
    ),
    fetchTheme: createQuery([tenant, 'shopify-theme'], async (onlineStoreId: string, themeId: string) => {
      const { data } = await apiClient.get<ShopifyTheme>(
        `/brands/${tenant}/shopify/proxy/${onlineStoreId}?path=${encodeURIComponent(`/themes/${themeId}`)}`
      )
      return data
    }),
    fetchProductDetails: createQuery([tenant, 'shopify-theme'], async (onlineStoreId: string, productId: string) => {
      const { data } = await apiClient.get<ShopifyProductDetails>(
        `/brands/${tenant}/shopify/proxy/${onlineStoreId}?path=${encodeURIComponent(`/products/${productId}/details`)}`
      )
      return data
    }),
    fetchApp: createQuery([tenant, 'shopify-app'], async (onlineStoreId: string) => {
      const { data } = await apiClient.get<ShopifyApp>(
        `/brands/${tenant}/shopify/proxy/${onlineStoreId}?path=${encodeURIComponent(`/app`)}`
      )
      return data
    }),
    updateCart: async (onlineStoreId: string, { themeId }: UpdateParams) => {
      const { data } = await apiClient.post<void>(
        `/brands/${tenant}/shopify/proxy/${onlineStoreId}?path=${encodeURIComponent(`/themes/cart/update`)}`,
        { themeId }
      )
      return data
    },
    updateProduct: async (onlineStoreId: string, { themeId }: UpdateParams) => {
      const { data } = await apiClient.post<void>(
        `/brands/${tenant}/shopify/proxy/${onlineStoreId}?path=${encodeURIComponent(`/themes/product/update`)}`,
        { themeId }
      )
      return data
    },
    publishToWeb: async (onlineStoreId: string, productId: string, publish: boolean) => {
      await apiClient.post(
        `/brands/${tenant}/shopify/proxy/${onlineStoreId}?path=${encodeURIComponent(`/products/${productId}/publish`)}`,
        {
          publish,
        }
      )
    },
  }
}

export default useShopifyProxyService
