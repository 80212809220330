import { Answer, AnswerType } from '@packages/types'
import classNames from 'classnames'
import React from 'react'

import CheckBoxCheckmark from 'icons/custom/checkbox-checkmark.svg'
import { FontService } from 'utils/loaders'

import OutOfStockBadge from './OutOfStockBadge'
import withAnswerTheme, { WithAnswerThemeProps } from './withAnswerTheme'

export type LabelAnswerProps = Answer &
  WithAnswerThemeProps & {
    isMultiAnswer?: boolean
    disabled?: boolean
    isOutOfStock?: boolean
  }

export default withAnswerTheme<LabelAnswerProps>(
  ({ type, selected, disabled, style, extraClassNames, views, name, isMultiAnswer, isOutOfStock }) => {
    if (type === AnswerType.Font && views?.[0]?.type === AnswerType.Font && views[0]?.font && views[0]?.assets) {
      FontService.loadFont({ fontFamily: views[0].font, type: views[0].fontType, asset: views[0].assets })
      style.fontFamily = `"${views[0].font}"`
    }

    return (
      <div
        className={classNames('font-choice', { disabled }, extraClassNames)}
        style={style}
        data-testid="label-answer"
      >
        {name}
        <div className="label-answer__badges">
          {isOutOfStock && (
            <OutOfStockBadge
              style={{
                marginTop: `calc(0.5rem - ${selected ? style.selectedAnswerBorderWidth : style.unselectedAnswerBorderWidth})`,
                marginRight: `calc(0.5rem - ${selected ? style.selectedAnswerBorderWidth : style.unselectedAnswerBorderWidth})`,
              }}
            />
          )}
          {isMultiAnswer && selected && (
            <div className={classNames('label-answer__check', { '--padded': isOutOfStock })}>
              <CheckBoxCheckmark color={style.selectedAnswerBorderColor} width={14} height={14} />
            </div>
          )}
        </div>
      </div>
    )
  }
)
