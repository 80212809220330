import { DenormalizedCustomizableQuestion } from '@packages/types'
import React from 'react'

import { useBottomDrawer } from 'themes/common/components'

import ColorPickerBottomDrawer from './ColorPickerBottomDrawer'
import ColorPickerButton from './ColorPickerButton'
import useColorPickerQuestion from './useColorPickerQuestion'

import './ColorPickerQuestion.scss'

interface ColorPickerQuestionProps {
  question: DenormalizedCustomizableQuestion
  createColorAnswer: (questionId: string, color: string) => void
  updateColorAnswer: (questionId: string, answerId: string, color: string) => void
  selectAnswer: (questionId: string, answerId: string) => void
}

const MobileColorPickerQuestion = ({
  question,
  createColorAnswer,
  updateColorAnswer,
  selectAnswer,
}: ColorPickerQuestionProps) => {
  const drawer = useBottomDrawer({ dismissOnSwipeDown: true, getSnapPoints: () => [296] })
  const { color, handleChange, handleChangeEnd, selectColorAnswer, selectDesignColor, colorAnswers, designColors } =
    useColorPickerQuestion({ question, createColorAnswer, updateColorAnswer, selectAnswer })

  return (
    <div className="question-panel-section color-picker-question-drawer">
      {drawer.isVisible && (
        <ColorPickerBottomDrawer
          drawer={drawer}
          question={question}
          color={color}
          onChange={handleChange}
          onChangeEnd={handleChangeEnd}
          colorAnswers={colorAnswers}
          onAnswerSelect={selectColorAnswer}
          designColors={designColors}
          onDesignColorSelect={selectDesignColor}
          onBackdropClick={drawer.dismiss}
          onNotchClick={drawer.dismiss}
        />
      )}
      <ColorPickerButton onClick={() => drawer.show()} question={question} colorAnswers={colorAnswers} />
    </div>
  )
}

export default MobileColorPickerQuestion
