import { DenormalizedQuote, Design } from '@packages/types'
import { StyleSheet, View, Text } from '@react-pdf/renderer'
import React from 'react'

import { BORDER_COLOR, FONT_BOLD, NEUTRAL_400, NEUTRAL_600, NEUTRAL_900 } from './assets'
import PrintableQuoteTableBody from './PrintableQuoteTableBody'
import PrintableQuoteTableFooter from './PrintableQuoteTableFooter'

interface PrintableQuoteTableProps {
  design: Design
  quote: DenormalizedQuote
}

const styles = StyleSheet.create({
  table: {
    width: 'auto',
    marginTop: 12,
  },
  tableRow: {
    flexDirection: 'row',
  },
  tableCol: {
    width: '35%',
    borderColor: NEUTRAL_600,
    borderTopColor: BORDER_COLOR,
    borderTopWidth: 1,
    paddingTop: 12,
    paddingBottom: 12,
  },
  tableEmptyCol: {
    width: '35%',
    borderColor: NEUTRAL_600,
    borderTopColor: BORDER_COLOR,
    borderTopWidth: 1,
    paddingTop: 8,
  },
  tableColHeader: {
    color: NEUTRAL_600,
    fontSize: 8,
    textTransform: 'uppercase',
  },
  tableColRightAlign: {
    textAlign: 'right',
  },
  tableColDescription: {
    width: '80%',
  },
  tableColInnerItems: {
    width: '35%',
    marginBottom: 8,
    paddingTop: 0,
    paddingBottom: 4,
  },
  tableColInnerItemBigger: {
    paddingBottom: 0,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    columnGap: 8,
  },
  tableRowWithItemImage: {
    display: 'flex',
    alignItems: 'center',
  },
  tableColProductTitle: {
    paddingTop: 12,
    paddingBottom: 10,
  },
  tableColNoPrice: {
    color: NEUTRAL_400,
  },
  extraPriceTitle: {
    fontSize: 10,
    color: NEUTRAL_600,
  },
  extraPriceTitleBulk: {
    marginLeft: 8,
  },
  itemTitle: {
    color: NEUTRAL_900,
    fontFamily: FONT_BOLD,
    fontSize: 12,
  },
  totalPrice: {
    color: NEUTRAL_900,
    fontFamily: FONT_BOLD,
    fontSize: 12,
  },
  bulkDesignImage: {
    width: '12%',
    height: 'auto',
    marginLeft: 8,
  },
  itemTotalSection: {
    fontFamily: FONT_BOLD,
  },
})

const PrintableQuoteTable = ({ design, quote }: PrintableQuoteTableProps) => {
  return (
    <View style={styles.table}>
      <View style={styles.tableRow}>
        <View style={[styles.tableCol, styles.tableColHeader, styles.tableColDescription]}>
          <Text>DETAILS</Text>
        </View>
        <View style={[styles.tableCol, styles.tableColHeader]}>
          <Text>QTY</Text>
        </View>
        <View style={[styles.tableCol, styles.tableColHeader]}>
          <Text>UNIT PRICE</Text>
        </View>
        <View style={[styles.tableCol, styles.tableColHeader, styles.tableColRightAlign]}>
          <Text>AMOUNT</Text>
        </View>
      </View>
      <PrintableQuoteTableBody quote={quote} design={design} styles={styles} />
      <PrintableQuoteTableFooter quote={quote} styles={styles} />
    </View>
  )
}

export default PrintableQuoteTable
