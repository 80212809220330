import React, { useContext } from 'react'

import QuoteContext from 'cms/quotes/QuoteContext'
import { Table } from 'common/components'

import QuoteTableBody from './QuoteTableBody'
import QuoteTableFooter from './QuoteTableFooter'

interface QuoteTableProps {
  itemIndex: number
}

const QuoteTable = ({ itemIndex }: QuoteTableProps) => {
  const { quote, isLoadingQuotes } = useContext(QuoteContext)

  if (isLoadingQuotes || !quote || !quote.items[itemIndex].design) {
    return (
      <div className="w-full pl-6 py-2 pr-2">
        <Table>
          <Table.BodyLoader numberOfColumns={4} numberOfRows={10} />
        </Table>
      </div>
    )
  }

  return (
    <Table data-testid="quote-summary">
      <Table.Header className="h-11">
        <Table.HeaderRow>
          <Table.HeaderCell className="w-6/12 py-3 px-6">
            <span className="font-medium text-neutral-600">DETAILS</span>
          </Table.HeaderCell>
          <Table.HeaderCell className="w-2/12">
            <span className="font-medium text-neutral-600">QTY</span>
          </Table.HeaderCell>
          <Table.HeaderCell className="w-2/12">
            <span className="font-medium text-neutral-600">UNIT PRICE</span>
          </Table.HeaderCell>
          <Table.HeaderCell className="w-2/12 text-right px-6">
            <span className="font-medium text-neutral-600">AMOUNT</span>
          </Table.HeaderCell>
        </Table.HeaderRow>
      </Table.Header>

      <QuoteTableBody itemIndex={itemIndex} />
      <QuoteTableFooter />
    </Table>
  )
}

export default QuoteTable
