import { Addon, CustomPlan, Plan, TransactionFeeType } from '@packages/types'
import React from 'react'

import { Card } from 'common/components'
import Capterra from 'icons/custom/capterra.svg'
import FourPointFiveStar from 'icons/custom/four-point-five-star.svg'
import G2Icon from 'icons/custom/g2.svg'
import Asmonaco from 'img/asmonaco-logo.png'
import DSG from 'img/dsg-logo.png'
import Puma from 'img/puma-logo.png'
import Suunto from 'img/suunto-logo.png'

export interface TotalCardContentProps {
  plan: Plan | CustomPlan
  addons: Addon[]
}

const TotalCardContent = ({ plan, addons }: TotalCardContentProps) => {
  const totalAddonPrice = addons.reduce<number>((acc, cur) => acc + cur.price, 0)

  return (
    <>
      <div className="flex flex-col w-full">
        <h1>Total price</h1>
        <span className="text-3xl leading-none pt-4 pb-2 font-normal">
          ${plan.price.recurringAmount + totalAddonPrice}
        </span>
        <span className="text-sm leading-none font-medium">Per month</span>
        {plan.price.type === TransactionFeeType.Flat && (
          <span className="font-normal w-full">+{plan.price.transactionFeePercentage}% transaction fee</span>
        )}
      </div>
      <Card.Separator />
      <div className="flex flex-col space-y-4 items-left text-sm leading-[18px] font-medium">
        <span>Our reviews speak for themselves</span>
        <div className="flex items-center">
          <G2Icon className="w-6 h-6 mr-4" />
          <Capterra className="w-14 mr-5" />
          <div className="h-7 border-r border-neutral-100 mr-5" />
          <FourPointFiveStar className="h-4" />
        </div>
        <span>Trusted by world-class brands</span>
        <div className="flex items-center">
          <img className="w-1/4" src={Puma} />
          <img className="w-1/4" src={DSG} />
          <img className="w-1/4" src={Asmonaco} />
          <img className="w-1/4" src={Suunto} />
        </div>
      </div>
    </>
  )
}

export default TotalCardContent
