import { useFlags } from 'flagsmith/react'
import React, { useContext } from 'react'
import { useHistory, useLocation } from 'react-router'

import { Page } from 'cms/layout'
import QuoteContext from 'cms/quotes/QuoteContext'
import { defaultPersistenceState } from 'cms/quotes/utils'
import { IconButton, Tag } from 'common/components'
import LeftArrow from 'icons/bold/52-Arrows-Diagrams/01-Arrows/arrow-left-1.svg'
import RightArrow from 'icons/bold/52-Arrows-Diagrams/01-Arrows/arrow-right-1.svg'
import classMerge from 'utils/classMerge'
import { formatFullDate } from 'utils/dateUtils'

import { STATUS_FILTERS } from '../Quotes/QuotesFilterList'
import QuoteHeaderActions from './QuoteHeaderActions'
import QuoteHeaderIntegrationSection from './QuoteHeaderIntegrationSection'

type QuoteHeaderProps = {
  baseUrl: string
}

const QuoteHeader = ({ baseUrl }: QuoteHeaderProps) => {
  const flags = useFlags(['quote_phase_2'])
  const { quote, handleGoBack, isLoadingQuotes, isFetchingQuotes, quoteSiblings, isEditing } = useContext(QuoteContext)
  const status = STATUS_FILTERS.find(filter => filter.name === quote?.status)
  const history = useHistory()

  const location = useLocation<{ persistence?: typeof defaultPersistenceState }>()
  const persistence = location.state?.persistence

  return (
    <Page.Header>
      <div className="flex">
        <IconButton
          Icon={LeftArrow}
          onClick={handleGoBack}
          className="print:hidden mr-2"
          aria-label="Go back"
          data-testid="go-back-button"
          type="button"
        />

        <h1>
          {!isLoadingQuotes && quote ? (
            <div className="flex-col">
              <div className="flex items-center mb-2">
                <div className="pl-2 pr-3 border-neutral-100 flex items-center">
                  QT{quote.quoteId}
                  <span className="ml-3 pt-0 flex">
                    <Tag
                      aria-label="Quote status"
                      className={classMerge(status?.className, 'capitalize', 'color-neutral-800')}
                    >
                      {quote.status}
                    </Tag>
                    {quote.sent && (
                      <Tag aria-label="Quote sent" className="bg-secondary-orange-75 ml-2 color-neutral-800">
                        Sent
                      </Tag>
                    )}
                  </span>
                </div>
              </div>
              <div className="flex divide-x divide-neutral-100">
                <div className="text-xs font-medium text-neutral-600 px-3 h-4">
                  <QuoteHeaderIntegrationSection quote={quote} />
                  <span>{quote.createdAt && formatFullDate(quote.createdAt)}</span>
                </div>
              </div>
            </div>
          ) : (
            <div className="animate-pulse h-8 bg-neutral-75 rounded max-w-xs w-64" />
          )}
        </h1>
      </div>
      {!isLoadingQuotes && quote ? (
        <div className="flex space-x-3">
          <QuoteHeaderActions />
          {!!persistence && !isEditing && (
            <>
              {flags.quote_phase_2.enabled && <hr className="h-auto border border-neutral-100" />}
              <div className="flex space-x-2">
                <IconButton
                  type="button"
                  Icon={LeftArrow}
                  className="print:hidden"
                  aria-label="Previous quote"
                  data-testid="previous-quote-button"
                  disabled={isFetchingQuotes || !quoteSiblings.previous}
                  onClick={() =>
                    quoteSiblings.previous &&
                    history.replace(`${baseUrl}/quotes/${quoteSiblings.previous.id}`, location.state)
                  }
                />
                <IconButton
                  type="button"
                  Icon={RightArrow}
                  className="print:hidden"
                  aria-label="Next quote"
                  data-testid="next-quote-button"
                  disabled={isFetchingQuotes || !quoteSiblings.next}
                  onClick={() =>
                    quoteSiblings.next && history.replace(`${baseUrl}/quotes/${quoteSiblings.next.id}`, location.state)
                  }
                />
              </div>
            </>
          )}
        </div>
      ) : (
        <div className="animate-pulse h-8 bg-neutral-75 rounded max-w-xs w-64" />
      )}
    </Page.Header>
  )
}

export default QuoteHeader
