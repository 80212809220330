import * as normalize from '@packages/normalizer'
import { BulkOrder, CustomizableQuestion, DenormalizedCustomizableQuestion, Group, GroupType } from '@packages/types'
import { generateId } from '@packages/unique-string'
import { set } from 'lodash/fp'
import { AnyAction } from 'redux'

import { reducer as customizationReducer } from 'customizer/customization'
import type { CustomizationState } from 'customizer/customization/reducer/reducer'
import selectAnswer from 'customizer/customization/reducer/selectAnswer'
import selectAnswers from 'customizer/customization/reducer/selectAnswers'
import setQuestionDefaultConfiguration from 'customizer/customization/reducer/setQuestionDefaultConfiguration'
import startCustomization from 'customizer/customization/reducer/startCustomization'
import startDesignCustomization from 'customizer/customization/reducer/startDesignCustomization'
import * as questionPanelActionTypes from 'customizer/questionPanel/actionTypes'

import * as customizationTypes from '../customization/actionTypes'
import * as actionTypes from './actionTypes'
import { bulkOrderRowCustomizationSelector } from './selectors'

interface ActionWithPayload extends AnyAction {
  payload: any
}

export type BulkOrderValue = Pick<
  CustomizableQuestion,
  | 'id'
  | 'selectedAnswer'
  | 'selectedAnswers'
  | 'restrictions'
  | 'hadInteraction'
  | 'hasFilteredWord'
  | 'hasRequiredError'
>
export type BulkOrderRow = { values: BulkOrderValue[]; quantity: number; id: string }
export type DenormalizedBulkOrderRow = Omit<BulkOrderRow, 'values'> & {
  questions: DenormalizedCustomizableQuestion[]
}

type BulkOrderState = {
  isFormVisible: boolean
  rows: BulkOrderRow[]
  shownRowIndex: number
  previewedRowIndex: number
  deletingIndex?: number | null
}

const initialState: BulkOrderState = {
  isFormVisible: false,
  rows: [],
  shownRowIndex: 0,
  previewedRowIndex: 0,
  deletingIndex: null,
}

const applyQuestionToBulkOrderRow = (row: BulkOrderRow, state: CustomizationState) => {
  const newValues = row.values.map(({ id }) => {
    const question = state.questions[id]

    return {
      id,
      selectedAnswer: question.selectedAnswer,
      selectedAnswers: question.selectedAnswers,
      restrictions: question.restrictions,
      hadInteraction: question.hadInteraction,
      hasFilteredWord: question.hasFilteredWord,
      hasRequiredError: question.hasRequiredError,
    }
  })

  return { ...row, values: newValues }
}

const createBulkOrderRowFromBulkOrder = (
  bulkOrderGroup: BulkOrder,
  questions: Record<string, CustomizableQuestion>,
  configuration?: Record<string, string | string[]>,
  quantity = 1
): BulkOrderRow => {
  const bulkOrderQuestions = Object.values(questions).filter(question => bulkOrderGroup.children.includes(question.id))
  return {
    values: bulkOrderQuestions.map(question => {
      const configurationQuestion = configuration?.[question.id]

      let selectedAnswer = question.selectedAnswer
      let selectedAnswers = question.selectedAnswers

      if (configurationQuestion) {
        if (Array.isArray(configurationQuestion)) {
          selectedAnswers = configurationQuestion
        } else {
          selectedAnswer = configurationQuestion
        }
      }

      return {
        id: question.id,
        selectedAnswer,
        selectedAnswers,
        restrictions: question.restrictions,
        hadInteraction: question.hadInteraction,
        hasFilteredWord: question.hasFilteredWord,
        hasRequiredError: question.hasRequiredError,
      }
    }),
    quantity,
    id: generateId('BULK-ORDER-ROW'),
  }
}

const createBulkOrderRowFromCustomization = (action: AnyAction): BulkOrderRow => {
  let customization = action.customizationState as CustomizationState

  const groups = Object.values(customization.groups)
  const bulkOrderGroup = groups.find(group => group.type === GroupType.BulkOrder) as BulkOrder | undefined
  const bulkQuestionIds = bulkOrderGroup?.children || []

  bulkQuestionIds.forEach(questionId => {
    customization = setQuestionDefaultConfiguration(customization, { payload: questionId })
  })

  return {
    values: bulkQuestionIds.map(questionId => {
      const question = customization.questions[questionId]

      return {
        id: questionId,
        selectedAnswer: question.selectedAnswer,
        selectedAnswers: question.selectedAnswers,
        restrictions: question.restrictions,
        hadInteraction: false,
        hasFilteredWord: false,
        hasRequiredError: false,
      }
    }),
    quantity: 1,
    id: generateId('BULK-ORDER-ROW'),
  }
}

export default (state: BulkOrderState = initialState, action: AnyAction) => {
  switch (action.type) {
    case actionTypes.SET_DELETING_INDEX:
      return { ...state, deletingIndex: action.payload.index }
    case actionTypes.SET_FORM_VISIBILITY:
      return { ...state, isFormVisible: action.payload }
    case actionTypes.ADD_ITEM:
      return { ...state, rows: [...state.rows, createBulkOrderRowFromCustomization(action)] }
    case actionTypes.REMOVE_ITEM:
      return {
        ...state,
        deletingIndex: null,
        rows: [...state.rows.filter((_row, index) => index !== action.payload.index)],
      }
    case actionTypes.RESET_ROWS:
      return { ...state, rows: [createBulkOrderRowFromCustomization(action)] }
    case actionTypes.UPDATE_SHOWN_ROW_INDEX:
      return { ...state, shownRowIndex: action.payload.index as number }
    case actionTypes.UPDATE_PREVIEWED_ROW_INDEX:
      return { ...state, previewedRowIndex: action.payload.index as number }
    case actionTypes.UPDATE_ROW_QUANTITY: {
      const { rowIndex, quantity } = action.payload

      return {
        ...state,
        rows: [...state.rows.map((row, index) => (index === rowIndex ? ({ ...row, quantity } as BulkOrderRow) : row))],
      }
    }
    case actionTypes.UPDATE_ROW_CUSTOMIZATION: {
      const row = state.rows[action.payload.rowIndex]
      const denormalizedRow = bulkOrderRowCustomizationSelector(action.payload.customization, row)
      const updatedRow = customizationReducer(denormalizedRow, action.payload.action)
      const normalizedRow = applyQuestionToBulkOrderRow(row, updatedRow)

      return { ...state, rows: set(action.payload.rowIndex, normalizedRow, state.rows) as unknown as BulkOrderRow[] }
    }
    case customizationTypes.SELECT_ANSWER: {
      const groups = Object.values(action.customizationState.groups) as Group[]
      const bulkOrderGroup = groups.find(group => group.type === GroupType.BulkOrder) as BulkOrder | undefined
      const isQuestionInBulkOrderGroup = bulkOrderGroup?.children.includes(action.payload.questionId)

      return {
        ...state,
        rows: isQuestionInBulkOrderGroup
          ? state.rows
          : state.rows.map(row => {
              const rowCustomization = bulkOrderRowCustomizationSelector(action.customizationState, row)
              const newCustomization = selectAnswer(rowCustomization, action as ActionWithPayload)

              return applyQuestionToBulkOrderRow(row, newCustomization)
            }),
      }
    }
    case customizationTypes.SELECT_ANSWERS: {
      const groups = Object.values(action.customizationState.groups) as Group[]
      const bulkOrderGroup = groups.find(group => group.type === GroupType.BulkOrder) as BulkOrder | undefined
      const isQuestionInBulkOrderGroup = bulkOrderGroup?.children.includes(action.payload.questionId)

      return {
        ...state,
        rows: isQuestionInBulkOrderGroup
          ? state.rows
          : state.rows.map(row => {
              const rowCustomization = bulkOrderRowCustomizationSelector(action.customizationState, row)
              const newCustomization = selectAnswers(rowCustomization, action as ActionWithPayload)

              return applyQuestionToBulkOrderRow(row, newCustomization)
            }),
      }
    }
    case customizationTypes.START_CUSTOMIZATION: {
      const { groups, questions } = startCustomization({} as any, action)

      const groupsArray = Object.values(groups)
      const bulkOrderGroup = groupsArray.find(group => group.type === GroupType.BulkOrder) as BulkOrder | undefined

      if (!bulkOrderGroup) return state

      return {
        ...state,
        rows: [createBulkOrderRowFromBulkOrder(bulkOrderGroup, questions)],
      }
    }
    case customizationTypes.START_DESIGN_CUSTOMIZATION: {
      const { customizerProduct } = action.payload

      const { groups } = normalize.customizerProduct.Normalizer.run(customizerProduct, 'customizerProducts')

      const groupsArray = Object.values(groups) as Group[]
      const bulkOrderGroup = groupsArray.find(group => group.type === GroupType.BulkOrder) as BulkOrder | undefined

      if (!bulkOrderGroup || !Array.isArray(action.payload.configuration)) return state

      return {
        ...state,
        rows: action.payload.configuration.map(
          ({ configuration, quantity }: { configuration: Record<string, string | string[]>; quantity: number }) => {
            const { questions } = startDesignCustomization({} as any, {
              ...action,
              payload: { ...action.payload, configuration },
            })

            return createBulkOrderRowFromBulkOrder(bulkOrderGroup, questions, configuration, quantity)
          }
        ) as BulkOrderRow[],
      }
    }
    case questionPanelActionTypes.SELECT_STEP:
      return {
        ...state,
        shownRowIndex: action.payload.stepId === 'root-step' && state.rows.length > 1 ? null : state.shownRowIndex,
      }
    default:
      return state
  }
}
