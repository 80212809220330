import { Asset, BrandBusiness, DenormalizedQuote } from '@packages/types'
import { StyleSheet, Document, Page, View } from '@react-pdf/renderer'
import React from 'react'

import { BORDER_COLOR, FONT } from './assets'
import PrintableQuoteCustomerDetails from './PrintableQuoteCustomerDetails'
import PrintableQuoteDesignDetails from './PrintableQuoteDesignDetails'
import PrintableQuoteHeader from './PrintableQuoteHeader'
import PrintableQuotePriceDetails from './PrintableQuotePriceDetails'

interface PrintableQuoteProps {
  quote: DenormalizedQuote
  designUrl?: string
  infos?: BrandBusiness
  logo?: Asset
}

const styles = StyleSheet.create({
  page: {
    fontSize: 10,
    paddingTop: 30,
    paddingBottom: 30,
    fontFamily: FONT,
  },
  verticalLine: {
    borderBottomWidth: 1,
    borderBottomColor: BORDER_COLOR,
    width: '100%',
  },
})

const PrintableQuote = ({ quote, designUrl, infos, logo }: PrintableQuoteProps) => {
  const design = quote.items[0].design

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <PrintableQuoteHeader infos={infos} brandLogo={logo} quote={quote} />
        <View style={styles.verticalLine} />
        <PrintableQuoteCustomerDetails quote={quote} />
        <View style={styles.verticalLine} />
        <PrintableQuoteDesignDetails designUrl={designUrl} design={design} />
        <View style={styles.verticalLine} />
        <PrintableQuotePriceDetails design={design} quote={quote} />
      </Page>
    </Document>
  )
}

export default PrintableQuote
