import React from 'react'

import { Button, Modal, ModalProps } from 'common/components'

interface BusinessInfoMissingModalProps extends Omit<ModalProps, 'children'> {
  onDownloadClick: () => void
  onAddInfoClick: () => void
  onClose: () => void
}

const BusinessInfoMissingModal = ({
  onDownloadClick,
  onAddInfoClick,
  onClose,
  ...modalProps
}: BusinessInfoMissingModalProps) => {
  return (
    <Modal size="medium" {...modalProps}>
      <Modal.CloseButton onClick={onClose} />
      <Modal.Title>Business information missing</Modal.Title>
      <Modal.Details>
        Please make sure to add at least your business name and email. These informations will be displayed on the quote
        PDF.
      </Modal.Details>
      <Modal.Actions>
        <Button
          onClick={() => {
            onDownloadClick()
            onClose()
          }}
          type="button"
        >
          Download
        </Button>
        <Button variant="primary" onClick={onAddInfoClick} type="button">
          Add information
        </Button>
      </Modal.Actions>
    </Modal>
  )
}

export default BusinessInfoMissingModal
