import { PaymentStrategy, SubscriptionStatus } from '@packages/types'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import React, { useContext } from 'react'
import { useHistory } from 'react-router'

import { useOnlineStoreService } from 'cms/onlineStores'
import { AddOnlineStoreModal } from 'cms/onlineStores/components'
import { useSubscriptionService } from 'cms/subscription'
import { TrackerContext } from 'cms/tracking/components'
import { ProductLimitReachedToast, ProgressBar, useModal, useToast } from 'common/components'
import { trpc } from 'common/hooks/trpc'
import { useProductService } from 'common/products'

import ActivatePlanPopover from './ActivatePlanPopover'
import CompletedProgressCenter from './CompletedProgressCenter'
import CreateProductPopover from './CreateProductPopover'
import InstallPopover from './InstallPopover'
import SelectThemePopover from './SelectThemePopover'

export interface ProgressCenterProps {
  baseUrl: string
}

const ProgressCenter = ({ baseUrl }: ProgressCenterProps) => {
  const { openCustomToast, openGenericErrorToast } = useToast()
  const history = useHistory()
  const tracker = useContext(TrackerContext)
  const onlineStoreService = useOnlineStoreService()
  const subscriptionService = useSubscriptionService()
  const productService = useProductService()
  const trpcUtils = trpc.useContext()
  const queryClient = useQueryClient()
  const addOnlineStoreModal = useModal()

  const { data: onlineStores, isFetched: areOnlineStoresFetched } = useQuery(
    onlineStoreService.fetchAll.queryKeys,
    onlineStoreService.fetchAll,
    { initialData: [] }
  )

  const { data: subscription } = useQuery(subscriptionService.fetchOne.queryKeys, subscriptionService.fetchOne)

  const { data: activityLogs, isFetched: areActivityLogsFetched } = trpc.activityLog.listLatest.useQuery(
    ['selectTheme', 'publishTheme', 'publishCustomizerProduct', 'addStartingPointToStore', 'completeOnboarding'],
    { initialData: [] }
  )

  const { mutate: createActivityLog } = trpc.activityLog.create.useMutation({
    onSuccess: () => trpcUtils.activityLog.listLatest.invalidate(),
  })

  const { mutate: autoConnect } = useMutation((productId: string) => productService.autoConnect(productId))

  const { mutate: createProduct } = useMutation(productService.create, {
    onSuccess(product) {
      autoConnect(product.id)
      history.push(`${baseUrl}/products/${product.id}/builder`)
      tracker.send('account_product_added')
      queryClient.invalidateQueries(productService.fetchAll.queryKeys)
    },
    onError(error: AxiosError & { data?: { status: number; name: string } }) {
      if (error.data?.status == 403 && error.data?.name === 'MaximumActiveProductsException') {
        return openCustomToast(props => (
          <ProductLimitReachedToast
            onUpgradePlan={() => history.push(`${baseUrl}/account/subscription/change-plan`)}
            {...props}
          />
        ))
      }
      openGenericErrorToast('Your product was not created.')
    },
  })

  const hasCompletedOnboarding = !!activityLogs.find(log => log.type === 'completeOnboarding')

  if (!areActivityLogsFetched || !areOnlineStoresFetched || hasCompletedOnboarding) return null

  const hasActivePlan =
    subscription?.status && ![SubscriptionStatus.Future, SubscriptionStatus.FreeTrial].includes(subscription.status)

  const stripeFreeTrialActivated =
    subscription?.status === SubscriptionStatus.FreeTrial &&
    subscription.paymentStrategy === PaymentStrategy.Stripe &&
    !!subscription.stripePaymentMethodId

  const isInstallCompleted = onlineStores.length > 0
  const isSelectAThemeCompleted = !!activityLogs.find(log => ['selectTheme', 'publishTheme'].includes(log.type))
  const isPublishProductCompleted = !!activityLogs.find(log => log.type === 'publishCustomizerProduct')
  const isSubscriptionActive = hasActivePlan || stripeFreeTrialActivated
  const stepStatuses = [isInstallCompleted, isSelectAThemeCompleted, isPublishProductCompleted, isSubscriptionActive]
  const progressPercentage = (stepStatuses.filter(Boolean).length / stepStatuses.length) * 100

  if (!stepStatuses.includes(false)) {
    return <CompletedProgressCenter onClose={() => createActivityLog({ type: 'completeOnboarding' })} />
  }

  return (
    <>
      <div className="flex flex-col border-neutral-100 border-t p-4 pb-8">
        <h2>Start selling in a few steps!</h2>
        <ProgressBar percentage={progressPercentage} containerClassName="bg-neutral-200" />

        <div className="flex flex-col space-y-3">
          <InstallPopover onAddStore={addOnlineStoreModal.open} isCompleted={isInstallCompleted} />
          <SelectThemePopover baseUrl={baseUrl} isCompleted={isSelectAThemeCompleted} />
          <CreateProductPopover isCompleted={isPublishProductCompleted} onCreateProduct={createProduct} />
          <ActivatePlanPopover baseUrl={baseUrl} isCompleted={isSubscriptionActive} />
        </div>
      </div>
      {addOnlineStoreModal.isVisible && (
        <AddOnlineStoreModal onClose={addOnlineStoreModal.close} {...addOnlineStoreModal.modalProps} />
      )}
    </>
  )
}

export default ProgressCenter
